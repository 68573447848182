<template>
  <div class="modal modal_wrap">
    <div class="modal_popup">
      <span class="modal__close" @click="close">
         <svg-icon name="times"/>
        </span>
      <Form class="form" @submit.prevent="cloneOrder">
        <label class="form__label">
          <span class="form__labelTitle">Enter new name</span>
          <Field class="form__input"
                 type="text"
                 name="name"
                 v-model="name"
                 :rules="'required'"
          />
          <ErrorMessage class="form__error"  name="name" />
        </label>

        <div class="justify-content-between d-flex">
          <button type="button" class="btn btn_transparent" @click="close"><svg-icon name="solid/step-backward" /> Back</button>
          <button class="btn btn_transparent"><svg-icon name="solid/check" />  Continue</button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ClonePackage",

    data() {
      return {
        name: this.oldName + '_new'
      }
    },

    computed: {

    },

    props: {
      id: Number,
      oldName: String,
    },

    methods: {
      close() {
        this.$store.commit('packages/setClone', false);
      },

      cloneOrder() {
       /* this.$validator.validate().then(valid => {
          if (valid) {*/
            this.$store.dispatch('packages/clone', {id: this.id, name:this.name});
            this.$store.commit('packages/setClone', false);
         /* }
        });*/
      }
    }
  }
</script>

<style scoped>

</style>
